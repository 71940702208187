*{padding: 0px;
  margin:  0px;
  border: none;
  outline: none;
  text-decoration: none;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;}
html, body {
  width: 100%;
  min-height: 100%; 
}
h1,h2,h3,h4,h5,p{
  letter-spacing:0px;
  
}
.logo{
  cursor: pointer;
}
section{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

nav{
  display: flex;
  justify-content: space-between;
}


.hamburger-btn{
  font-size: 1.3rem;
  position: absolute;
  bottom: 12px;
  width: 45px;
  height: 45px;
  right: 15px;
  outline: none;
  background-color: white;
  color: #000;
  border-radius: 100%;
}
.addTransiton{
  transform: translateX(250px);
  transition: transform 0.5s ease-in-out;
}
.removeTransition{
  transform: translateX(20px);
  transition: transform 0.5s ease-in-out;
}

#sidebar-list{
  background-color: #99BFE5;
  height: 90vh;
  width: 250px;
  position: absolute;
  z-index: 2000;
  right: 0;
  top: 0;
  margin-top: 70px;
}
